<template>
  <container
    :style="{
      height: chatDashboard ? (smallFormat ? '95%' : '100%') : smallFormat ? '500px' : '600px',
      width: chatDashboard ? '100%' : smallFormat ? '350px' : '400px'
    }"
    style="border-radius: 0"
    size="7xl"
  >
    <div
      style="height: 100%"
      :style="{
        margin: smallFormat && chatDashboard ? '0 !important' : '',
        width: smallFormat && chatDashboard ? '100% !important' : ''
      }"
    >
      <card-list style="height: 100% !important; border-radius: 0" class="chat-card-list">
        <card-list-item v-if="!chatDashboard" style="height: 4%; width: 100%; border-radius: 0">
          <div class="flex flex-col justify-start" style="width: 100%">Direct Message</div>
        </card-list-item>
        <card-list-item
          class="chat-list-message-container"
          style="padding: 0em; height: 78%; border-radius: 0"
        >
          <scroll-container
            v-if="messageList.length"
            class="flex flex-col px-3 pt-2"
            style="width: 100%"
          >
            <div class="flex flex-row justify-center text-muted" ref="messageTop">
              <btn v-if="lastEvaluatedKey" class="sm round info" @click="queryMessages"
                >Load more</btn
              >
            </div>
            <div
              v-for="(message, index) in messageList"
              :key="index"
              :class="message.userId === userId ? 'justify-end' : 'justify-start'"
              class="flex flex-row chat-message-container"
            >
              <div
                class="flex flex-col chat-message-inner-container"
                :class="message.userId === userId ? 'items-end' : 'items-start'"
              >
                <div class="chat-message-user-name" v-if="message.userId !== userId">
                  {{ getUserName(message) }}
                </div>
                <div
                  class="chat-message"
                  :class="message.userId === userId ? 'sent-message' : 'received-message'"
                >
                  {{ message.body }}
                </div>
              </div>
            </div>
            <div style="height: 0.1em" ref="messageBottom" />
          </scroll-container>
          <div v-else style="text-align: center">
            No messages!
            <br /><br />
            Write a message to your {{ editable ? 'client.' : 'contractor.' }}
          </div>
        </card-list-item>
        <card-list-item style="height: 10%; min-height: 90px; border-radius: 0">
          <div class="flex flex-row items-center justify-around" style="width: 100%; height: 100%">
            <textarea
              v-model="newMessage"
              placeholder="Write your message"
              class="chat-text-area"
              @focusin="scrollToBottom(true)"
              @keydown.enter.prevent
              @keyup.enter.prevent="sendMessage"
            />
            <btn
              class="info round"
              :class="chatDashboard ? 'lg' : 'sm'"
              @click="sendMessage"
              :hide-text="true"
            >
              <template #icon>
                <font-awesome-icon icon="arrow-up" />
              </template>
            </btn>
          </div>
        </card-list-item>
      </card-list>
    </div>
  </container>
</template>

<script>
export default {
  name: 'QuoteBody',
  data() {
    return {
      newMessage: '',
      refreshChat: 0,
      chatUserNames: {}
    }
  },
  props: {
    editable: {
      required: true
    },
    refId: {
      required: true
    },
    chatDashboard: {
      required: false,
      default: false
    },
    quoteName: {
      required: false
    },
    contractorId: {
      required: true
    },
    companyId: {
      required: true
    }
  },
  mounted() {
    this.scrollToBottom()
    if (this.chatDashboard) {
      this.$store.dispatch('updateLastTimeActive', {
        refId: this.refId,
        userId: this.userId,
        userName: this.userName
      })
    }
  },
  beforeUnmount() {
    if (this.chat) {
      this.$store.dispatch('updateLastTimeActive', {
        refId: this.refId,
        userId: this.userId,
        userName: this.userName
      })
    }
  },
  computed: {
    userId() {
      return this.$store.state.session.user.user_id
    },
    userName() {
      const user = this.$store.state.session.user
      if (!user.user_fname && !user.user_lname) {
        if (user.user_email) {
          return user.user_email
        }
        return this.$t('Anonymous')
      }
      return `${user.user_fname ? user.user_fname : ''} ${user.user_lname ? user.user_lname : ''}`
    },
    smallFormat() {
      return this.$store.getters.smallFormat
    },
    chat() {
      this.refreshChat
      if (!this.$store.state.chat.chatSummary || !this.$store.state.chat.chatSummary[this.refId]) {
        return null
      }
      return this.$store.state.chat.chatSummary[this.refId]
    },
    messageList() {
      if (!this.chat || !this.chat.messages) return []
      return this.chat.messages.messageList
    },
    lastEvaluatedKey() {
      if (!this.chat || !this.chat.messages) return null
      return this.chat.messages.lastEvaluatedKey
    }
  },
  watch: {
    messageList(newVal, oldVal) {
      if (newVal[0] === oldVal[0]) {
        setTimeout(() => this.scrollToBottom(true), 200)
      }
    }
  },
  methods: {
    async queryMessages() {
      await this.$store.dispatch('loadMoreMessages', {
        refId: this.refId
      })
    },
    async sendMessage() {
      // create the message
      await this.$store.dispatch('sendChatMessage', {
        refId: this.refId,
        userId: this.userId,
        body: this.newMessage
      })

      // if there is no chat created (this is the first message sent) create the chat
      // note: the already created messaged will be returned with the created chat.
      if (!this.chat) {
        await this.$store.dispatch('createNewChat', {
          refId: this.refId,
          chatName: this.quoteName || this.refId,
          userId: this.userId,
          contractorId: this.contractorId,
          companyId: this.companyId,
          userName: this.userName
        })
        this.refreshChat++
        this.$emit('resubscribeToMessages')
      }

      this.newMessage = ''
    },
    getUserName(message) {
      if (!this.chat || !this.chat.chatUsers || !this.chat.chatUsers) return
      const user = this.chat.chatUsers.filter((chatUser) => chatUser.userId === message.userId)
      if (!user || !user.length) return 'User'
      else return user[0].userName
    },
    scrollToBottom(smooth = false) {
      const el = this.$refs.messageBottom
      const options = { block: 'end', inline: 'nearest', behavior: 'auto' }
      if (smooth) options.behavior = 'smooth'
      if (el) el.scrollIntoView(options)
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.chat-card-list {
  .list-group {
    margin: 0 !important;
    width: 100% !important;
  }
}

.chat-list-message-container {
  .scroll-container--inner {
    width: 100%;
  }
}

.chat-text-area {
  height: 100%;
  width: 85%;
  resize: none;
  padding: 0.5em;
  border: solid 2px $cool-gray-300;
}

.chat-message-container {
  min-height: 2em;
  margin: 1em 0;

  .chat-message-inner-container {
    width: 100%;

    .chat-message-user-name {
      font-size: 0.8em;
    }

    .chat-message {
      max-width: 75%;
      padding: 0.8em;
      border-radius: 10px;

      &.sent-message {
        background-color: #58a8f4;
        color: white;
      }

      &.received-message {
        background-color: $cool-gray-200;
        border: solid 2px $cool-gray-200;
      }
    }
  }
}
</style>
